import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';

import DashboardApp from './components/dashboard/DashboardApp.js';
import LoginApp from './components/login/LoginApp.js';
import UserDetailApp from './components/user/UserDetailApp.js';
import UserConfirmApp from './components/user/UserConfirmApp.js';
import UserSuccessApp from './components/user/UserSuccessApp.js';
import UserListApp from './components/user/UserListApp.js';
import UserDeleteApp from './components/user/UserDeleteApp.js';

import DriverListApp from './components/drivers/DriverListApp.js';
import DriverDetailApp from './components/drivers/DriverDetailApp.js';
import DriverConfirmApp from './components/drivers/DriverConfirmApp.js';
import DriverSuccessApp from './components/drivers/DriverSuccessApp.js';
import DriverDeleteApp from './components/drivers/DriverDeleteApp.js';

import PartnerDetailApp from './components/partners/PartnerDetailApp.js';
import PartnerConfirmApp from './components/partners/PartnerConfirmApp.js';
import PartnerSuccessApp from './components/partners/PartnerSuccessApp.js';
import PartnerListApp from './components/partners/PartnerListApp.js';
import PartnerDeleteApp from './components/partners/PartnerDeleteApp.js';


import ClientDetailApp from './components/client/ClientDetailApp.js';
import ClientConfirmApp from './components/client/ClientConfirmApp.js';
import ClientSuccessApp from './components/client/ClientSuccessApp.js';
import ClientListApp from './components/client/ClientListApp.js';
import ClientDeleteApp from './components/client/ClientDeleteApp.js';

import ProjectDetailApp from './components/project/ProjectDetailApp.js';
import ProjectConfirmApp from './components/project/ProjectConfirmApp.js';
import ProjectSuccessApp from './components/project/ProjectSuccessApp.js';
import ProjectListApp from './components/project/ProjectListApp.js';
import ProjectDeleteApp from './components/project/ProjectDeleteApp.js';
import ProjectDriverDetailApp from './components/project/driver/ProjectDriverDetailApp.js';
import ProjectDriverConfirmApp from './components/project/driver/ProjectDriverConfirmApp.js';
import ProjectDriverSuccessApp from './components/project/driver/ProjectDriverSuccessApp.js';
import ProjectDriverDeleteApp from './components/project/driver/ProjectDriverDeleteApp.js';

import ReportDetailApp from './components/report/ReportDetailApp.js';
import ReportMobDetailApp from './components/report/ReportMobApp.js';
import ReportMobSuccessApp from './components/report/ReportMobSuccessApp.js';
import ReportConfirmApp from './components/report/ReportConfirmApp.js';
import ReportSuccessApp from './components/report/ReportSuccessApp.js';
import ReportListApp from './components/report/ReportListApp.js';
import ReportMyListApp from './components/report/ReportMyListApp.js';

import MonthlyReportDetailApp from './components/monthly_report/MonthlyReportDetailApp.js';
import MonthlyReportListApp from './components/monthly_report/MonthlyReportListApp.js';

import MonthlyReceiptListApp from './components/monthly_receipt/MonthlyReceiptListApp.js';
import MonthlyReceiptDetailApp from './components/monthly_receipt/MonthlyReceiptDetailApp.js';
import MonthlyPaymentListApp from './components/monthly_payment/MonthlyPaymentListApp.js';
import MonthlyPaymentDetailApp from './components/monthly_payment/MonthlyPaymentDetailApp.js';

import PartnerLoginApp from './components/login/PartnerLoginApp.js';
import PartnerDashboardApp from './components/dashboard/PartnerDashboardApp.js';

import CompanyDetailApp from './components/company/CompanyDetailApp.js';

config.set(configuration);

class App extends React.Component{
render(){
return(
    <div className="main">
      <Routes>
        <Route  path='/' element={<LoginApp />} />
        <Route  path='/login' element={<LoginApp />} />
        <Route  path='/dashboard' element={<DashboardApp />} />
        
        <Route  path='/user' element={<UserListApp />} />
        <Route  path='/user/confirm' element={<UserConfirmApp />} />
        <Route  path='/user/success' element={<UserSuccessApp />} />
        <Route  path='/user/success/:id' element={<UserSuccessApp />} />
        <Route  path='/user/:id' element={<UserDetailApp />} />
        <Route  path='/user/:id/delete' element={<UserDeleteApp />} />

        <Route  path='/driver' element={<DriverListApp />} />
        
        <Route  path='/driver/partner/:partner_id' element={<DriverListApp />} />
        <Route  path='/driver/:id' element={<DriverDetailApp />} />
        <Route  path='/driver/confirm' element={<DriverConfirmApp />} />
        <Route  path='/driver/success' element={<DriverSuccessApp />} />
        <Route  path='/driver/success/:id' element={<DriverSuccessApp />} />
        <Route  path='/driver/:id/delete' element={<DriverDeleteApp />} />

        <Route  path='/partner/:id' element={<PartnerDetailApp />} />
        <Route  path='/partner/confirm' element={<PartnerConfirmApp />} />
        <Route  path='/partner/success' element={<PartnerSuccessApp />} />
        <Route  path='/partner/success/:id' element={<PartnerSuccessApp />} />
        <Route  path='/partner' element={<PartnerListApp />} />
    
        <Route  path='/partner/:id/delete' element={<PartnerDeleteApp />} />

        <Route  path='/client' element={<ClientListApp />} />
        <Route  path='/client/:id' element={<ClientDetailApp />} />
        <Route  path='/client/confirm' element={<ClientConfirmApp />} />
        <Route  path='/client/success' element={<ClientSuccessApp />} />
        <Route  path='/client/success/:id' element={<ClientSuccessApp />} />
        <Route  path='/client/:id/delete' element={<ClientDeleteApp />} />

        <Route  path='/project' element={<ProjectListApp />} />
        <Route  path='/project/:id' element={<ProjectDetailApp />} />
        <Route  path='/project/confirm' element={<ProjectConfirmApp />} />
        <Route  path='/project/success' element={<ProjectSuccessApp />} />
        <Route  path='/project/success/:id' element={<ProjectSuccessApp />} />
        <Route  path='/project/:id/delete' element={<ProjectDeleteApp />} />

        <Route  path='/project/:project_id/driver/:project_driver_id' element={<ProjectDriverDetailApp />} />
        <Route  path='/project/:project_id/driver/confirm' element={<ProjectDriverConfirmApp />} />
        <Route  path='/project/:project_id/driver/success' element={<ProjectDriverSuccessApp />} />
        <Route  path='/project/:project_id/driver/success/:id' element={<ProjectDriverSuccessApp />} />
        <Route  path='/project/:project_id/driver/:id/delete' element={<ProjectDriverDeleteApp />}   />

        <Route  path='/project/:project_id/report/mob/:project_driver_id/:id' element={<ReportMobDetailApp />} />
        <Route  path='/project/:project_id/report/mob/:project_driver_id/:id/:year/:month/:day' element={<ReportMobDetailApp />} />
        <Route  path='/project/:project_id/report/mob/:project_driver_id/:id/:driver_id' element={<ReportMobDetailApp />} />
        <Route  path='/project/report/success/mob' element={<ReportMobSuccessApp />} />
        <Route  path='/project/:project_id/report/:project_driver_id/:id' element={<ReportDetailApp />} />
        <Route  path='/project/:project_id/report/:project_driver_id/:id/confirm' element={<ReportConfirmApp />} />
        <Route  path='/project/report/success' element={<ReportSuccessApp />} />
        <Route  path='/project/report/success/:id' element={<ReportSuccessApp />} />

        <Route  path='/report/mine' element={<ReportMyListApp />} />
        <Route  path='/report' element={<ReportListApp />} />
        <Route  path='/report/:spot' element={<ReportListApp />} />
        
        
        <Route  path='/project/:project_id/monthly_report/:project_driver_id/:year/:month' element={<MonthlyReportDetailApp />} />
        <Route  path='/project/:project_id/monthly_report/:project_driver_id/:year/:month/:id' element={<MonthlyReportDetailApp />} />
        <Route  path='/project/:project_id/monthly_report/driver/:driver_id/:year/:month' element={<MonthlyReportDetailApp />} />
        <Route  path='/project/:project_id/monthly_report/driver/:driver_id/:year/:month/:id' element={<MonthlyReportDetailApp />} />

        <Route  path='/project/:project_id/monthly_receipt/:project_driver_id/:year/:month' element={<MonthlyReceiptDetailApp />} />
        <Route  path='/project/:project_id/monthly_receipt/e/:id/:project_driver_id/:year/:month' element={<MonthlyReceiptDetailApp />} />
        

        <Route  path='/project/:project_id/monthly_payment/:driver_id/:year/:month' element={<MonthlyReceiptDetailApp />} />
        <Route  path='/project/:project_id/monthly_payment/e/:id/:driver_id/:year/:month' element={<MonthlyReceiptDetailApp />} />
        

        <Route  path='/monthly_receipt' element={<MonthlyReceiptListApp />} />
        <Route  path='/monthly_report' element={<MonthlyReportListApp />} />
        <Route  path='/monthly_payment' element={<MonthlyPaymentListApp />} />
        <Route  path='/monthly_payment/:partner_id/:year/:month/driver/:driver_id/:id' element={<MonthlyPaymentDetailApp />} />
        <Route  path='/monthly_payment/:partner_id/:year/:month/:id' element={<MonthlyPaymentDetailApp />} />
        <Route  path='/monthly_payment/:partner_id/:year/:month/:id/:history_id' element={<MonthlyPaymentDetailApp />} />
        <Route  path='/monthly_payment/:partner_id/:year/:month/driver/:driver_id/:id/:history_id' element={<MonthlyPaymentDetailApp />} />

        <Route  path='/partners/login' element={<PartnerLoginApp />} />
        <Route  path='/partners/dashboard' element={<PartnerDashboardApp />} />

        <Route  path='/company' element={<CompanyDetailApp />} />
        
      </Routes>   
    </div>
)}}

const container = document.getElementById('root')

if(container){
  const root = createRoot(container)
  root.render((<BrowserRouter ><App /></BrowserRouter>))
}
