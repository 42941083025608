import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
//import logo from "../../../images/logo.png"

import './header.css';
import useLoginState from '../../../components/login/useLoginState';
import useAuthState from '../../auth/useAuthState';

export const Header = ({profile, noauth, /** onLogin, onLogout, onCreateAccount **/ }) => {
  const {logout} = useLoginState()
  const {token, auth_error, getToken} = useAuthState()


  useEffect(() => {

    if(!token && token !== null && noauth !== true){
      window.location.href = "/login"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {

    if(auth_error && noauth !== true){
      window.location.href = "/login"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[auth_error])


  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const logoutExe = () => {

    logout()
    if(profile && profile.user_type === "partner"){
      window.location.href = "/partners/login"
    }else{
      window.location.href = "/login"
    }
    
  }

  return(
  
    <header>
          <div className="inner">
              <div className="row between">
                  <div className="logo">

                    <div>軽貨物月報システム</div>
                    <div className="version"> version 1.2.50 &nbsp;&nbsp;  </div>
                  </div>
                  <div className="logaut">
                      <div className="row align_center">
                          <p>{profile ? profile.name : ""}</p>
                          <p> {profile  && profile.company ? <div className="company-name">[{profile.company.name}]</div>  : null} </p>
                           {profile ? <div className="logaut_btn" onClick={logoutExe}>{profile ? "ログアウト" : ""}</div>  : null}
                      </div>
                  </div>
              </div>
          </div>
      </header>
);}

Header.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
  }),
  noauth : Boolean | null
  /** onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired, **/
};

Header.defaultProps = {
  profile: null,
  noauth :false
};

/**<img src={logo} alt="HOUND REPRT SYSTEM LOGO" />
 * 
 */