import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { addMonths,format } from 'date-fns';
import { PageApp } from '../../stories/PageApp'
import { NonUserPageApp } from '../../stories/NonUserPageApp';
import MonthlyPaymentDetail from "./MonthlyPaymentDetail"


import MonthlyPayment from './MonthlyPayment';
import Company from '../company/Company';
import Driver from '../drivers/Driver';
import AddressInfo from '../company/AddressInfo';
import PaymentItem from './PaymentItem';


import usePartnerState from '../partners/usePartnerState';
import useMonthlyPaymentState from './useMonthlyPaymentState';
import useMonthlyReportState from '../monthly_report/useMonthlyReportState';
import useReportState from '../report/useReportState'
import useDriverState from '../drivers/useDriverState';
import useProjectState from '../project/useProjectState'
import useProjectDriverState from '../project/driver/useProjectDriverState';
import useCompanytState from '../company/useCompanyState';
//import MonthlyReport from '../monthly_report/MonthlyReport';


function MonthlyPaymentDetailApp(props:PropsValue) {
  const { partner_id } = useParams();
  const { id } = useParams();
  const { year } = useParams();
  const { month } = useParams();
  const { history_id } = useParams();
  const { driver_id } = useParams();
  const [profile, setProfile] = useState(null)
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  const [target_company, setCompany] = useState(null)
  const [driver_item, setDriverItem] = useState(null)
  const [editable, setEditable] = useState(false)
  const [last_updated_at, setLastUpdatedAt] = useState(null)
  const [message, setMessage] = useState(null)

  //const [m_report_list, setMonthlyReportList] = useState(null)
  //const [m_report_list_updated_at, setMonthlyReportListUpdatedAt] = useState(null)
 
  const [updated_at, setUpdatedAt] = useState(null) 
  const {partner, getPartner} = usePartnerState()
  const {driver, getDriver} = useDriverState()
  const {project_list_updated_at, project_list, getProjectList} = useProjectState()
  const {monthly_payment, monthly_payment_created,monthly_payment_list,  monthly_list_updated_at, createMonthlyPayment,getMontylyPaynmentList, getMontylyPaynmentListByDriver, confirm} = useMonthlyPaymentState()
  const {project_driver_list_updated_at, my_project_list, getListByIds} = useProjectDriverState()
  const {report_list, list_updated_at, getReportList, getReportListByPartner} = useReportState()
  const {monthly_report_list, monthly_report_list_updated_at, clearMonthlyReportList, createListByReportList} = useMonthlyReportState()
  const {company, getPublicCompanyDetail} = useCompanytState()
  useEffect(() => {
    if(token){
      getPartner(token, partner_id)
      
      if(driver_id){
        //自社ドライバー
        getDriver(token,driver_id)  
        getMontylyPaynmentListByDriver(token, partner_id, driver_id, year, month)
      }else{
        //協力会社
        getMontylyPaynmentList(token, partner_id, year, month)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  useEffect(() => {
   
    if(monthly_report_list){  

      if(monthly_report_list.length > 0){
        const last_updated_at_report = monthly_report_list.reduce((a,b)=>a.updated_at>b.updated_at?a:b)
        setLastUpdatedAt(last_updated_at_report.updated_at)
      }
      setUpdatedAt(new Date())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_report_list_updated_at])

  useEffect(() => {
    
    if(project_list && my_project_list){

      createReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list_updated_at,project_driver_list_updated_at])


  useEffect(() => {
    if(my_project_list){
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver_list_updated_at])


  useEffect(() => {
    
    if(report_list){
  
      if(project_list && my_project_list){
       
        createReport()
      }else{

         //project一覧を取得
        const project_ids = report_list.map(report => {
          return report.project_id
        })
        const initialValue = []
        const flat_ids = project_ids.reduce((project_ids, obj) => project_ids.includes(obj) ? project_ids : [...project_ids, obj], initialValue)
        getProjectList(token,1,null,{ids:flat_ids}, 0)

        //Porject_Driver一覧を取得
        const project_driver_ids = report_list.map(report => {
          return report.project_driver_id
        })
        const initialDValue = []
        const flat_d_ids = project_driver_ids.reduce((project_driver_ids, obj) => project_driver_ids.includes(obj) ? project_driver_ids : [...project_driver_ids, obj], initialDValue)
        getListByIds(token,flat_d_ids)
      }
    }
    //setProjectDriverList(my_project_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])


  useEffect(() => {
    if(driver){
      setDriverItem(new Driver(driver))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])



  useEffect(() => {
    if(company){
      setCompany(company)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[company])


  useEffect(() => {
    if(monthly_payment_created){
      
  
      /**if(driver_id){
        window.location.href = "/monthly_payment?inhouse=1" 
      }else{
        window.location.href = "/monthly_payment" 
      }**/
     setMessage(<div className="fixed-message">保存されました。</div>)
     setItem(new MonthlyPayment(monthly_payment))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_payment_created ,monthly_payment_created])

  useEffect(() => {

    if(monthly_list_updated_at){
  
      /**
       * いずれにしても最新情報は取得  (Reportを読み切る)
       *  最新情報はp
       */
      reload()
   
      if(monthly_payment_list.length !== 0){
       
        /** 保存済みがある場合 */
        const target = history_id ? monthly_payment_list.filter(history => history.id === Number(history_id))[0]:(monthly_payment_list[0])
        target.content = JSON.parse(target.content)
        target.content.repot_list = target.content.repot_list.map(item => {
          return new PaymentItem(item)
        })
   
        target.content.deduction_list = target.content.deduction_list.map(item => {
          return new PaymentItem(item)
        })
        if(target.content.fixed_list){
          target.content.fixed_list = target.content.fixed_list.map(item => {
            return new PaymentItem(item)
          })
        }
        setItem(new MonthlyPayment(target)) 
        
        getPublicCompanyDetail(token, target.company_id)
        //const company =  new Company(JSON.parse(target.company_info))
        //setCompany(company)
      }else{
        
        setItem(new MonthlyPayment()) 
      }  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_list_updated_at])

  const createReport = () => {
    project_list.map(project => {
      createListByReportList(year, month, project, report_list, my_project_list)
      return null
    })
  }

  const updateByHistory = (history) => {
    if(driver_id){
      window.location.href = "/monthly_payment/" + partner_id + "/" + year + "/" + month + "/driver/" + driver_id + "/e/" + history.id  
    }else{
      window.location.href = "/monthly_payment/" + partner_id + "/" + year + "/" + month + "/e/" + history.id  
    }
    
  }


  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const confirmHandler = ( id, is_confirm, is_return , comment ) => {
    confirm(token,  id, is_confirm, is_return , comment)
  }

  const updateHandler = (repot_list, deduction_list,fixed_list, params, is_confirm) => {
    //company_info: JSON.stringify(company),
    //companyInfoは 画像保存することになるので、一旦保存しない
    createMonthlyPayment(token, partner_id,   {year:Number(year), month:Number(month), report_type:1, driver_id:driver_id, is_confirm:is_confirm,  content:JSON.stringify( {repot_list:repot_list, deduction_list:deduction_list, fixed_list:fixed_list, params:params} )} )
  }
  

  const reload = () => {
    console.log(item)
    setItem( new MonthlyPayment() )
    const start_date = new Date(year, month - 1, 1)
      const end_date  = addMonths(start_date,2) //最大2ヶ月分読み込んでLocalで日付チェック
      if(driver_id){
        //getMyProjectList(token, format(new Date(year, month, 1),"yyyy-MM-dd"), null,null,null,driver_id,true,1,true)
        getReportList(token ,null,1,null,driver_id, format(start_date,"yyyy-MM-dd"), format(end_date,"yyyy-MM-dd"),null, true)
        
      }else{
        //reportを読み込む
        getReportListByPartner(token,start_date,end_date,partner_id)
      }
  }

  const reloadHandler = () => {
    clearMonthlyReportList()
    reload()
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
    setEditable(e && e.user_type === "finance")

    if(e.company){
      const address_info = new AddressInfo(e.company.address_info)
      const company =  new Company(e.company)
      company.set("address_info",address_info )
      setCompany(company)
    }
  }


  return(
    
    <div id="home">
      {message}
     {
        id === "e" || id === "partner" ? 
          <PageApp current_page="monthly_report"  content={<MonthlyPaymentDetail updated_at = {updated_at} profile={profile} last_updated_at={last_updated_at} company={target_company}  histories={monthly_payment_list}  monthly_reports={monthly_report_list}  btn={editable } driver={driver_item} token={token} year={year} month={month} partner={partner} monthly_payment={item}  updateHandler={updateHandler} reload={reloadHandler} updateByHistory={updateByHistory} confirmHandler={confirmHandler}/>} profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} />
          :<NonUserPageApp content={<MonthlyPaymentDetail btn={false} token={token} profile={profile} company={target_company}  year={year} month={month}  driver={driver_item}  partner={partner} monthly_payment={item}  updateHandler={updateHandler} confirmHandler={confirmHandler}/>}  authChangeHandler={authChangeHandler}  />
     }
    </div>
     
  )
}

export default MonthlyPaymentDetailApp

